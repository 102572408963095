@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* OHIF Theme */

@layer base {
  :root {
    --highlight: 191 74% 63%;
    --background: 236 62% 5%;
    --foreground: 0 0% 98%;
    --card: 234 64% 10%;
    --card-foreground: 0 0% 98%;
    --popover: 219 90% 15%;
    --popover-foreground: 0 0% 98%;
    --primary: 214 98% 60%;
    --primary-foreground: 0 0% 98%;
    --secondary: 214 66% 48%;
    --secondary-foreground: 200 50% 84%;
    --muted: 234 64% 10%;
    --muted-foreground: 200 46% 65%;
    --accent: 217 79% 24%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 236 45% 21%;
    --ring: 214 98% 60%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --radius: 0.5rem;
    --success-bg: hsl(143, 85%, 96%);
    --success-border: hsl(145, 92%, 91%);
    --success-text: hsl(140, 100%, 27%);

    --info-bg: hsl(208, 100%, 97%);
    --info-border: hsl(221, 91%, 91%);
    --info-text: hsl(210, 92%, 45%);

    --warning-bg: hsl(49, 100%, 97%);
    --warning-border: hsl(49, 91%, 91%);
    --warning-text: hsl(31, 92%, 45%);

    --error-bg: hsl(359, 100%, 97%);
    --error-border: hsl(359, 100%, 94%);
    --error-text: hsl(360, 100%, 45%);
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 234 64% 10%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 214 98% 60%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 236 45% 21%;
    --ring: 214 98% 60%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

/* ORIGINAL THEME for comparison and testing

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 72.22% 50.59%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 5% 64.9%;
    --radius: 0.5rem;

    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }


  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;

    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

*/

/* Theme Copy Example

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;
    --primary: 262.1 83.3% 57.8%;
    --primary-foreground: 210 20% 98%;
    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
    --destructive: 360 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 262.1 83.3% 57.8%;
    --radius: 0.5rem;
    --chart-1: ;
    --chart-2: ;
    --chart-3: ;
    --chart-4: ;
    --chart-5: ;
  }

  .dark {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;
    --primary: 263.4 70% 50.4%;
    --primary-foreground: 210 20% 98%;
    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;
    --destructive: 360 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 263.4 70% 50.4%;
    --chart-1: ;
    --chart-2: ;
    --chart-3: ;
    --chart-4: ;
    --chart-5: ;
  }
}

*/
@layer base {
  html {
    font-family: 'Inter', sans-serif;
  }

  body {
    @apply !bg-black;
  }
}

h2.section-header {
  @apply py-4 text-2xl font-normal text-white;
}

h3.section-header {
  @apply py-3 text-xl text-white;
}

.row {
  @apply mb-6 flex flex-row flex-wrap rounded-md border py-10;
}

.example {
  @apply flex-initial px-6;
}

.example2 {
  @apply flex-initial px-4;
}

/* Additional CSS edits to components */

/* Tooltip */

.TooltipContent[data-side='bottom'] {
  animation-name: slideDown;
}

/* Custom CSS to hide default number input arrows */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  @apply appearance-none;
}

input[type='number'] {
  -moz-appearance: textfield;
  /* For Firefox */
}

/* CSS added by Rushit Patel */

.mainBgColor {
  background: #01303D !important;
}

.darkColorWithGradient {
  background: linear-gradient(#0a586c, #016f8b) !important;
}

.bg-secondary-dark {
  /* background: linear-gradient(#0a586c, #016f8b) !important; */
  background: #004152 !important;
}

.bg-customblue-400 {
  background: #C7D9DD !important;
}

.bg-bkg-med,
.bg-bkg-low {
  background: #004152 !important;
}

.sidebarBgDarkColor {
  background: #01303D !important;
}

.bg-popover {
  background: #025C74 !important;
}

.bgLight {
  background: #004152 !important;
}

.leftSideBarBtnBox {
  background: #004152 !important;
}

.allButtonGradient {
  background: #025C74 !important;
}

.bg-black {
  background: #ffffff !important;
}

.textWhite {
  color: #ffffff !important;
}

.cTitleText {
  width: 100% !important;
  color: #ffffff !important;
  text-transform: uppercase !important;
  font-size: 1rem !important;
  font-weight: bold !important;
}

.text-primary-active {
  color: white !important;
}

.text-primary-active-dark {
  color: #004152 !important;
}

.sidebarCloseIcon {
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff !important;
}

.iconDarkColor {
  color: #ffffff !important;
}

.iconLightDarkColor {
  color: #9eccd6 !important;
}

.darkBorderColor {
  border: .1rem solid #016f8b !important;
}

.darkStrokeColor {
  stroke: #016f8b !important;
}

.tooltip.tooltip-bottom .tooltip-box::before {
  height: .1rem !important;
  background-color: #016f8b !important;
}

.bgDark {
  background: #016f8b !important;
}

.bgLightDark {
  background: #9eccd6 !important;
}

.border-light {
  border-color: #9eccd6 !important;
}

.border-dark {
  border-color: #016f8b !important;
}

.bg-highlight {
  background: #025C74 !important;
}

.studyBox {
  background: #004152 !important;
  border-radius: .2rem !important;
}

.activeStudyBox {
  background: #004152 !important;
}

.text-muted-foreground {
  color: white !important;
}

.toggleBtnIcon button {
  color: #016f8b !important;
}

.activeToggle svg {
  color: #016f8b !important;
}

.activeToggleBtn {
  color: white !important;
  background: #004152 !important;
}

.activeToggleBtn svg {
  color: white !important;
}

.roundedBox {
  border-radius: .3rem;
}

.css-13cymwt-control {
  background-color: rgb(255, 255, 255) !important;
}

.textBlack {
  color: #ffffff !important;
  font-weight: bolder !important;
}

.iconList>div>div>div {
  width: 2rem !important;
  height: 2rem !important;
  padding: 0rem !important;
}

.iconList div div div button svg {
  width: 1.2rem !important;
  height: 1.2rem !important;
}

.sidebarActiveTab {
  background: #004152 !important;
}

.sidebarActiveTab span {
  color: white !important;
}

.darkBgForBtns {
  height: fit-content !important;
  background: #01303D !important;
  padding: .2rem .2rem .1rem .2rem !important;
  border-radius: .2rem !important;
}

.darkBtn {
  background: #025C74 !important;
}

.darkestBtn {
  background: #002A35 !important;
}

.svgColorWhite svg circle {
  fill: white !important;
}

.svgColorWhite svg path {
  stroke: #004152 !important;
}

.bgLightActive {
  background: #025C74 !important;
}

.hotkeyInputField {
  border: .1rem solid #025C74 !important;
  background: #01303D !important;
  color: #ffffff !important;
}

.searchInputField svg g {
  stroke: white !important;
}

.searchInputField svg path:first-child {
  stroke: none;
  /* Ensure no border is applied to the main path */
}

.searchInputField input {
  border: .1rem solid #025C74 !important;
  background: #01303D !important;
  color: #ffffff !important;
}

.searchInputField input::placeholder {
  color: #ffffff !important;
}

.tableTopBar {
  background: #025C74 !important;
}

.modelBox {
  background: #004152 !important;
}

.modelInnerBox {
  background: #01303D !important;
}

.boderColorInTable {
  border-color: #025C74 !important;
}

.closeIconBtn circle {
  fill: #025c74 !important;
}

.closeIconBtn path {
  stroke: #ffffff !important;
}

.bg-highlightWithWhite {
  background: #ffffff !important;
}

.bg-primary-dark {
  background: #004152 !important;
}

.blackBg {
  background: rgba(0, 0, 0, 0.5) !important;
}


/* ============================ Local page css */
.l_mainBox {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.l_innerBox {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 1rem;
  padding: 2rem;
}

.l_haderBox {
  display: flex;
  justify-content: space-between;
}

.l_title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #01303D;
}

.l_desc {
  font-size: .8rem;
  color: #01303D;
}

.l_uploadStar {
  height: 3rem;
}

.l_uploadImgBox {
  width: 100%;
  height: calc(100% - 8rem);
  border: .12rem dashed #01303D;
  border-radius: 1rem;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.l_uploadImg {
  width: 7rem;
}

.l_uploadText {
  color: #01303D;
  font-weight: 500;
  margin: 2rem 0;
}

.l_btnBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
}

.l_uploadBtn {
  color: #016F8B !important;
  background: none !important;
  font-weight: 500;
}

.l_noteData {
  color: #01303D;
  font-weight: 500;
}

.l_noteData span {
  color: #950404;
}
